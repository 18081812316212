<template>
    <div>
        <header-portrate />

        <div style="margin-bottom:70px;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />
        
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:101px !important;`">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.counter }}</td>
                            <td>{{ row.item.group_name }}</td>
                            <td>{{ row.item.permission }}</td>
                            
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

    </div>
        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
export default{
  components: { breadCrumbs, TabsComp, HeaderPortrate, Footer },
    data() {
        return {
            active_tab:0,
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function() {
            return [
                {
                    text: '',
                    align: 'center',
                    filterable: false,
                    value: 'counter',
                },
                {
                    text: this.lang.group_name,
                    align: 'center',
                    filterable: false,
                    value: 'group_name',
                },
                {
                    text: this.lang.permissions,
                    align: 'center',
                    filterable: false,
                    value: 'permission',
                },
            ]
        },
        tablerows: function() {
            return [
                {
                    counter: 1,
                    group_name: this.lang.administratior,
                    permission: this.lang.full_permissions
                },
                {
                    counter: 2,
                    group_name: this.lang.accountant,
                    permission: this.lang.accountatn_permissions,
                },
                {
                    counter: 3,
                    group_name: this.lang.cashier,
                    permission: this.lang.cashier_permissions
                },
                {
                    counter: 4,
                    group_name: "موظف الاستقبال ",
                    permission: "الصيانة"
                }
            ]
        },
            
        items: function () {
                return {
                    text: this.lang.permissions,
                    disabled: true,
                    to: '/settings/permissions-settings',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
}
</script>